<template>

	<div>
		<div class="block-header">
			<div class="row">
				<div class="col-lg-7 col-md-6 col-sm-12">
					<h2>签约项目
						<small class="text-muted"></small>
					</h2>
				</div>
			</div>
		</div>

		<div class="container-fluid">
			<div class="row clearfix">
				<div class="col-lg-12">
					<div class="card">
						<div class="body" @keyup.enter="queryList(1)">
							<el-row>
								<el-col :span="12">
									<el-button-group>
										<el-button type="primary" size="mini" icon="el-icon-plus"
											@click.native="go(`/project-signing-editor/${page.currentPage}`)">添加项目</el-button>
										<el-button v-if="role.type==='system'" type="danger" size="mini"
											icon="el-icon-delete" @click="del">
											删除项目
										</el-button>
									</el-button-group>
								</el-col>
							</el-row><br>
							<el-row>
								<el-col :span="12">
									<el-input placeholder="关键字检索 「项目名称 / 填报单位  / 产业类别 / 建设地点」" v-model="query_keyword" size="mini"
										class="input-with-select">
										<placeSelect v-model="query_type" placeholder="投资方所属地"  slot="prepend"></placeSelect>
										<el-button slot="append" icon="el-icon-search" @click="queryList(1)">
										</el-button>
									</el-input>
								</el-col>
								<el-col :span="4" :offset="1">
									<b>年份</b>: <el-select v-model="query_year" size="mini" placeholder="请选择"
										@change="queryList(1)">
										<el-option v-for="(item,index) in query_years" :key="index" :label="item" :value="item">
										</el-option>
									</el-select>
								</el-col>
							</el-row>
							<br /><br />

							<el-table ref="multipleTable" :data="list" style="width: 100%" border
								@selection-change="handleSelectionChange" height="calc(100vh - 380px)" size="mini">
								<el-table-column type="selection" width="40"></el-table-column>
								<el-table-column  label="项目名称" align="center">
									<template v-slot="scope">
										<el-tooltip content="查看" placement="bottom">
											<a href="javascript:void(0)" @click="go(`/project-signing-editor/${page.currentPage}/${scope.row.unique}`)" style="color: deepskyblue;">{{scope.row.项目名称}}</a>
										</el-tooltip>
									</template>
								</el-table-column>
								
								<el-table-column prop="填报单位" label="填报单位" align="center"></el-table-column>
								<el-table-column prop="投资方所属地" label="投资方所属地"  align="center"></el-table-column>
								<el-table-column prop="建设内容" label="建设内容" align="center" show-overflow-tooltip></el-table-column>
								<el-table-column prop="产业类别" label="产业类别" align="center"></el-table-column>
								<el-table-column prop="总投资额" label="总投资额" align="center"></el-table-column>
								<el-table-column prop="用地面积" label="用地面积" align="center"></el-table-column>
								<el-table-column prop="建设地点" label="建设地点" align="center"></el-table-column>
								<el-table-column prop="填报时间" label="填报时间" align="center" show-overflow-tooltip></el-table-column>

								<el-table-column label="审批状态" align="center">
									<template v-slot="scope">
										<el-tooltip v-if="scope.row.审批状态==='审批未通过'" class="item" effect="dark" :content="scope.row.审批意见"
											placement="top-start">
											<el-tag style="cursor: pointer;" :type="analyseType(scope.row.审批状态)">{{scope.row.审批状态}}</el-tag>
										</el-tooltip>
										<el-tag v-else :type="analyseType(scope.row.审批状态)">{{scope.row.审批状态}}</el-tag>
									</template>
								</el-table-column>
								
								
								<el-table-column label="操作" width="180" align="center">
									<template v-slot="scope">
										<el-button-group>

										<el-tooltip v-if="scope.row.modified.status==-1" content="审批通过的项目不可修改,如需修改请提交修改申请">
												<el-button @click="handleApplyForModifying(scope.row)" icon="el-icon-unlock" type="warning" size="mini"></el-button>
											</el-tooltip>

											<el-tooltip v-if="role.type==='system' && scope.row.modified.status==-2" content="修改审批" placement="top">
												<el-button @click="handleApprovalForModify(scope.row)" icon="el-icon-message-solid" type="primary" size="mini"></el-button>
											</el-tooltip>
											
											<el-button v-if="scope.row.modified.status>-1" @click="go(`/project-signing-editor/${page.currentPage}/${scope.row.unique}`)" icon="el-icon-edit" type="success" size="mini">编辑</el-button>
											<!-- <el-button v-else @click="go(`/project-signing-editor/${scope.row.unique}`)" size="mini">查看</el-button> -->

											<el-tooltip v-if="scope.row.next==0 && scope.row.审批状态==='审批通过'" content="将此项目提交到注册项目">
												<el-button  @click="submitNext(scope.row.unique)"  size="mini">提交</el-button>
											</el-tooltip>	
												
											<el-button v-if="role.type==='system' && scope.row.审批状态=='待审批'" @click="go(`/project-signing-editor/${page.currentPage}/${scope.row.unique}/approval`)" icon="el-icon-coordinate" type="danger" size="mini">审批</el-button>
											
											<el-tooltip v-if="scope.row.审批状态=='已保存' || scope.row.审批状态=='审批未通过'" content="申请该项目,需通过管理员审批">
												<el-button @click="apply(scope.row)" icon="el-icon-s-flag" type="primary" size="mini">申请</el-button>
											</el-tooltip>	

										</el-button-group>
									</template>
								</el-table-column>
							</el-table>
							<el-pagination style="margin-top: 40px;" @size-change="handleSizeChange"
								@current-change="queryList" :current-page="page.currentPage"
								:page-sizes="[10, 20, 30, 40]" :page-size="page.pageSize"
								layout="total, sizes, prev, pager, next, jumper" :total="page.count"></el-pagination>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	import placeSelect from '../components/PlaceSelect.vue' 
	import projectSigningController from "../controller/projectSigningController.js"
	import {projectSigningValidate} from '@/assets/js/check.js'
	export default {
		components:{
			placeSelect
		},
		data() {
			return {
				multipleSelection: [],
				list: [],
				query_type: '',
				query_keyword: '',
				query_year: '',
				query_years: [],
				page: {
					pageSize: 10,
					currentPage: 1,
				},
				user: {},
				role: {},

			}
		},
		created() {
			this.user = JSON.parse(sessionStorage.getItem('user'))
			this.role = JSON.parse(sessionStorage.getItem('role'))
      this.page.currentPage =  this.getParam('page')?parseInt(this.getParam('page')):1;
			this.initQueryYears()
		},
		methods: {
			apply(o) {
				if(projectSigningValidate(o,this)){
					let obj = this.deepClone(o);
					obj.审批状态 = '待审批';
					projectSigningController.put(obj).then(res => this.queryList(this.page.currentPage));
				}
			
			},
			submitNext(unique){
				
				projectSigningController.submitNext({unique:unique}).then(res => {
					this.queryList(this.page.currentPage);
				})
			},
			queryList(currentPage) {

				this.page.currentPage = currentPage ? currentPage : 1;
				projectSigningController.list({
					query_type: this.query_type,
					query_keyword: this.query_keyword,
					query_year: this.query_year,
					current_page: this.page.currentPage,
					page_size: this.page.pageSize
				}).then(res => {
					this.list = res.data.list;
					this.page = res.data.page;
				})

			},
			handleApplyForModifying(obj) {

				this.$prompt('申请修改项目信息，请填写理由', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
				}).then(({
					value
				}) => {
					console.log(2)
					if (value) {
						obj.modified.status = -2;
						obj.modified.applicant = this.user.name;
						obj.modified.applicant_dept = this.user.dept_name;
						obj.modified.reason = value;
						let o = this.deepClone(obj)
						projectSigningController.put(o).then(res => {
							this.queryList(this.page.currentPage);
						})
					}
				});

			},
			handleApprovalForModify(obj) {

				this.$confirm(
					`${obj.modified.applicant_dept} 的 ${obj.modified.applicant} 正在申请修改项目信息；申请理由：  ${obj.modified.reason}`,
					'提示', {
						distinguishCancelAndClose: true,
						confirmButtonText: '同意',
						cancelButtonText: '残忍拒绝',
						type: 'warning'
					}).then(() => {

					obj.modified.status = 2;
					obj.modified.applicant = '';
					obj.modified.applicant_dept = '';
					obj.modified.reason = '';
					let o = this.deepClone(obj)
					projectSigningController.put(o).then(res => {
						this.queryList(this.page.currentPage);
					})

				}).catch(action => {

					if (action === 'cancel') {

						obj.modified.status = -1;
						obj.modified.applicant = '';
						obj.modified.applicant_dept = '';
						obj.modified.reason = '';
						let o = this.deepClone(obj)
						projectSigningController.put(o).then(res => {
							this.queryList(this.page.currentPage);
						})
					}
				});

			},
			del() {
				if (this.multipleSelection.length > 0) {

					this.$confirm('此操作将永久删除勾选数据, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						let array = [];
						for (let i = 0; i < this.multipleSelection.length; i++)
							array.push(this.multipleSelection[i].unique)
						projectSigningController.del({
							uniques: array.toString()
						}).then(res => this.queryList(this.page.currentPage))
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消删除'
						})
					});

				} else {
					this.$message.error('请至少勾选一条数据')
				}
			},
			analyseType(type) {

				if (type === '待审批') {
					return 'warning';
				} else if (type === '审批未通过') {
					return 'danger';
				} else {
					return '';
				}
			},
			handleSizeChange(val) {
				this.page.pageSize = val;
				this.queryList()
			},
			initQueryYears() {
				projectSigningController.years().then(res => {
					this.query_years = res.data
					this.query_year = res.data[0]
					this.queryList(this.page.currentPage)
				})
			},
			toggleSelection(rows) {
				if (rows) {
					rows.forEach(row => {
						this.$refs.multipleTable.toggleRowSelection(row);
					});
				} else {
					this.$refs.multipleTable.clearSelection();
				}
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			}
		}

	}
</script>

<style scoped>
	.el-select {
		width: 130px;
	}

	.input-with-select .el-input-group__prepend {
		background-color: #fff;
	}
</style>
