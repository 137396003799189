<template>
	  <el-select v-model="selected" filterable  :placeholder="placeholder" clearable  @change="changeValue">
	    <el-option
	      v-for="(item,index) in options"
	      :key="index"
	      :label="item"
	      :value="item">
	    </el-option>
	  </el-select>
</template>

<script>
	export default {
		name:'PlaceSelect',
		props:{
			value:String,
			placeholder:String,
		},
		mounted() {
			this.selected = this.value;
		},
		data() {
			return {
				selected:'',
				options:['北京','天津','上海','广东','浙江','江苏','安徽','湖南','湖北','河南','河北','山东','重庆','陕西','江西','山西','广西', '四川','云南','海南','贵州','福建','黑龙江','辽宁','吉林','宁夏','青海','甘肃','新疆','西藏','内蒙古','香港','澳门','台湾']
			}
		},
		methods:{
			changeValue(newValue){
				this.selected = newValue
				this.$emit('input',newValue)
			}
		},
		watch:{
			value(newValue,oldValue){
				this.value = newValue
				this.selected = newValue
				console.log(this.selected)
			}
		}

	}
</script>

<style>
</style>
