export function projectTrackingValidate(form, vue) {

	if (!form.项目名称) {
		vue.$message.error('请填写项目名称')
		return false;
	}

	if (!form.建设地点) {
		vue.$message.error('请填写建设地点')
		return false;
	}

	if (!form.建设内容) {
		vue.$message.error('请填写建设内容')
		return false;
	}

	if (!form.产业类别) {
		vue.$message.error('请填写产业类别')
		return false;
	}

	if (!form.用地面积) {
		vue.$message.error('请填写用地面积')
		return false;
	}

	if (!form.投资方名称) {
		vue.$message.error('请填写投资方名称')
		return false;
	}

	if (!form.总投资额) {
		vue.$message.error('请填写总投资额')
		return false;
	}

	if (!form.投资方所属地) {
		vue.$message.error('请填写投资方所属地')
		return false;
	}

	if (!form.是否来往考察) {
		vue.$message.error('请填写是否来往考察')
		return false;
	}


	if (form.考察图片.length < 1) {
		vue.$message.error('请上传考察图片')
		return false;
	}

	if (!form.联系人) {
		vue.$message.error('请填写联系人')
		return false;
	}

	if (!form.联系人电话) {
		vue.$message.error('请填写联系人电话')
		return false;
	}

	if (!form.联系人职务) {
		vue.$message.error('请填写联系人职务')
		return false;
	}

	if (!form.跟踪人) {
		vue.$message.error('请填写跟踪人')
		return false;
	}

	if (!form.跟踪单位) {
		vue.$message.error('请填写跟踪单位')
		return false;
	}

	if (!form.项目进展情况) {
		vue.$message.error('请填写项目进展情况')
		return false;
	}

	if (!form.项目存在问题) {
		vue.$message.error('请填写项目存在问题')
		return false;
	}

	return true;

}

export function projectPreTrialValidate(form, vue) {

	if (!form.项目名称) {
		vue.$message.error('请填写项目名称')
		return false;
	}

	if (!form.建设地点) {
		vue.$message.error('请填写建设地点')
		return false;
	}

	if (!form.建设内容) {
		vue.$message.error('请填写建设内容')
		return false;
	}

	if (!form.产业类别) {
		vue.$message.error('请填写产业类别')
		return false;
	}

	if (!form.用地面积) {
		vue.$message.error('请填写用地面积')
		return false;
	}

	if (!form.投资方名称) {
		vue.$message.error('请填写投资方名称')
		return false;
	}

	if (!form.总投资额) {
		vue.$message.error('请填写总投资额')
		return false;
	}

	if (!form.投资方所属地) {
		vue.$message.error('请填写投资方所属地')
		return false;
	}


	if (!form.联系人) {
		vue.$message.error('请填写联系人')
		return false;
	}

	if (!form.联系人电话) {
		vue.$message.error('请填写联系人电话')
		return false;
	}

	if (!form.联系人职务) {
		vue.$message.error('请填写联系人职务')
		return false;
	}

	if (!form.跟踪人) {
		vue.$message.error('请填写跟踪人')
		return false;
	}

	if (!form.跟踪单位) {
		vue.$message.error('请填写跟踪单位')
		return false;
	}

	if (!form.项目进展情况) {
		vue.$message.error('请填写项目进展情况')
		return false;
	}

	if (!form.项目存在问题) {
		vue.$message.error('请填写项目存在问题')
		return false;
	}

	return true;

}


export function projectSigningValidate(form,vue) {

	if (!form.项目名称) {
		vue.$message.error('请填写项目名称')
		return false;
	}

	if (!form.建设地点) {
		vue.$message.error('请填写建设地点')
		return false;
	}

	if (!form.建设内容) {
		vue.$message.error('请填写建设内容')
		return false;
	}


	if (!form.外来投资方) {
		vue.$message.error('请填写外来投资方')
		return false;
	}

	if (!form.总投资额) {
		vue.$message.error('请填写总投资额')
		return false;
	}


	if (!form.行业类别) {
		vue.$message.error('请填写行业类别')
		return false;
	}

	if (!form.产业类别) {
		vue.$message.error('请填写产业类别')
		return false;
	}

	if (!form.用地面积) {
		vue.$message.error('请填写用地面积')
		return false;
	}

	if (!form.投资方所属地) {
		vue.$message.error('请填写投资方所属地')
		return false;
	}

	if (!form.签约时间) {
		vue.$message.error('请填写签约时间')
		return false;
	}


	if (form.合同文本附件.length < 1) {
		vue.$message.error('请上传合同文本附件')
		return false;
	}


	return true;

}


export function projectRegisterValidate(form,vue) {
				if (!form.项目名称) {
					vue.$message.error('请填写项目名称')
					return false;
				}

				if (!form.建设地点) {
					vue.$message.error('请填写建设地点')
					return false;
				}

				if (!form.用地面积) {
					vue.$message.error('请填写用地面积')
					return false;
				}

				if (!form.建设内容) {
					vue.$message.error('请填写建设内容')
					return false;
				}


				if (!form.外来投资方) {
					vue.$message.error('请填写外来投资方')
					return false;
				}

				if (!form.总投资额) {
					vue.$message.error('请填写总投资额')
					return false;
				}


				if (!form.行业类别) {
					vue.$message.error('请填写行业类别')
					return false;
				}

				if (!form.产业类别) {
					vue.$message.error('请填写产业类别')
					return false;
				}



				if (!form.投资方所属地) {
					vue.$message.error('请填写投资方所属地')
					return false;
				}

				if (!form.签约时间) {
					vue.$message.error('请填写签约时间')
					return false;
				}

				if (form.合同文本附件.length < 1) {
					vue.$message.error('请上传合同文本附件')
					return false;
				}


				if (!form.工商注册号) {
					vue.$message.error('请填写工商注册号')
					return false;
				}

				if (!form.注册名称) {
					vue.$message.error('请填写注册名称')
					return false;
				}

				if (!form.注册资本) {
					vue.$message.error('请填写注册资本')
					return false;
				}

				if (!form.法人代表) {
					vue.$message.error('请填写法人代表')
					return false;
				}

				if (!form.经营范围) {
					vue.$message.error('请填写经营范围')
					return false;
				}

				if (!form.注册时间) {
					vue.$message.error('请填写注册时间')
					return false;
				}

				// if (!form.本地股东) {
				// 	vue.$message.error('请填写本地股东')
				// 	return false;
				// }

				// if (!form.本地股东出资额) {
				// 	vue.$message.error('请填写本地股东出资额')
				// 	return false;
				// }

				// if (!form.市外股东) {
				// 	vue.$message.error('请填写市外股东')
				// 	return false;
				// }

				// if (!form.市外股东出资额) {
				// 	vue.$message.error('请填写市外股东出资额')
				// 	return false;
				// }

				if (!form.项目引荐单位) {
					vue.$message.error('请填写项目引荐单位')
					return false;
				}
				if (!form.所属产业链) {
					vue.$message.error('请填写所属产业链')
					return false;
				}



				return true;

			}
